import BigNumber from 'bignumber.js';
import { useStaticData } from 'providers/StaticDataStoreProvider';
import { bigNumberToStr } from 'utils/strings';

const TransactionHistoryToken = ({
  symbol,
  rootAddress,
  amount,
  usdValue,
  isNative,
}: {
  symbol: string;
  rootAddress: string;
  amount: BigNumber;
  usdValue: BigNumber;
  isNative: boolean;
}) => {
  const staticData = useStaticData();

  return (
    <div
      className={`flex items-center gap-1 text-sm ${
        amount.isPositive() ? 'text-success-2' : ''
      }`}
    >
      <img
        src={staticData.getTokenLogo(rootAddress, isNative)}
        className='w-5 h-auto'
        alt=''
      />
      <span className='font-bold'>{amount.isPositive() ? '+' : '-'}</span>
      <span>
        {`${bigNumberToStr(amount.isPositive() ? amount : amount.negated(), 3, {
          useShortNotation: true,
        })} ${symbol}`}
      </span>
      <span className='font-bold'>
        ($
        {bigNumberToStr(
          usdValue.isPositive() ? usdValue : usdValue.negated(),
          0
        )}
        )
      </span>
    </div>
  );
};

export default TransactionHistoryToken;
