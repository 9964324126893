import { observer, useLocalObservable } from 'mobx-react-lite';
import { useApi } from 'providers/ApiProvider';
import { createContext, useContext, useEffect } from 'react';
import { BundleStore } from '../stores/BundleStore';

const bundleStoreContext = createContext<BundleStore>({} as BundleStore);

export const ProvideBundleStore = observer(({ children }: any) => {
  const api = useApi();
  const store = useLocalObservable(() => new BundleStore(api));

  useEffect(() => {
    store.init();
  }, [store]);

  if (!store.initialized) {
    return null;
  }

  return (
    <bundleStoreContext.Provider value={store}>
      {children}
    </bundleStoreContext.Provider>
  );
});

export const useBundleStore = () => {
  return useContext(bundleStoreContext);
};
