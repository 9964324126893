import SnipaRoutes from 'routes';
import React, { useEffect } from 'react';
import ProfileHeader from './ProfileHeader';
import { observer } from 'mobx-react-lite';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import {
  ProvideProfilePortfolioStore,
  useProfilePortfolioStore,
} from '../providers/ProfilePortfolioStoreProvider';
import { ProvideTransactionHistoryStore } from 'providers/transaction-history/TransactionHistoryStoreProvider';
import ProfilePortfolio from './ProfilePortfolio';
import ProfileTransactionHistory from './ProfileTransactionHistory';
import ProfileAnalytics from './ProfileAnalytics';
import { ProvideProfileAnalyticsStore } from '../providers/ProfileAnalyticsStoreProvider';
import { showBetaFunctionality } from 'utils/beta';

const ProfilePage = observer(() => {
  const { address } = useParams();

  const profilePortfolioStore = useProfilePortfolioStore();

  useEffect(() => {
    if (!address) {
      return;
    }

    profilePortfolioStore.fetchPortfolio(address);
  }, [address, profilePortfolioStore]);

  return (
    <React.Fragment>
      <ProfileHeader address={address} />

      <Routes>
        <Route index element={<ProfilePortfolio />} />

        {showBetaFunctionality && (
          <Route
            path={SnipaRoutes.profile.history.path}
            element={<ProfileTransactionHistory address={address} />}
          />
        )}

        {showBetaFunctionality && (
          <Route
            path={SnipaRoutes.profile.analytics.path}
            element={<ProfileAnalytics address={address} />}
          />
        )}

        <Route path='*' element={<Navigate to='' />} />
      </Routes>
    </React.Fragment>
  );
});

const Profile = () => {
  return (
    <ProvideProfilePortfolioStore>
      <ProvideTransactionHistoryStore>
        <ProvideProfileAnalyticsStore>
          <ProfilePage />
        </ProvideProfileAnalyticsStore>
      </ProvideTransactionHistoryStore>
    </ProvideProfilePortfolioStore>
  );
};

export default Profile;
