import SnipaRoutes from 'routes';
import Table from 'components/core/table';
import { ReactComponent as ProfileLogoDark } from 'assets/images/profile-logo-dark.svg';
import { ProjectUserModel } from 'modules/project/models/ProjectUserModel';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Column } from 'react-table';
import { bigNumberToStr, cutString, unixDateToStr } from 'utils/strings';

const UsersListTable = ({ users }: { users: ProjectUserModel[] }) => {
  const columns = useMemo<Column<ProjectUserModel>[]>(
    () => [
      {
        Header: '',
        accessor: 'position',
        Cell: ({ row }) => <span>{row.original.position}</span>,
        disableSortBy: true,
        className: 'w-2 hidden md:table-cell',
      },
      {
        Header: 'User',
        accessor: 'address',
        Cell: ({ row }) => (
          <NavLink
            to={`/${SnipaRoutes.profile.path}/${row.original.address}`}
            className='underline'
          >
            <div className='flex items-center gap-2'>
              <ProfileLogoDark className='w-6 h-auto' />
              <span>{cutString(row.original.address, 6, 4)}</span>
            </div>
          </NavLink>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Updated at',
        accessor: 'updatedAt',
        Cell: ({ row }) => (
          <span>
            {row.original.updatedAt
              ? unixDateToStr(row.original.updatedAt, 'DD.MM.YYYY HH:mm')
              : ''}
          </span>
        ),
        disableSortBy: true,
        className: 'w-48 hidden md:table-cell',
      },
      {
        Header: 'Share',
        accessor: 'tvlSharePercent',
        Cell: ({ row }) => (
          <span>{bigNumberToStr(row.original.tvlSharePercent, 2)}%</span>
        ),
        disableSortBy: true,
        className: 'w-20 hidden md:table-cell',
      },
      {
        Header: 'Net worth',
        accessor: 'networth',
        Cell: ({ row }) => (
          <span className='font-bold'>
            {bigNumberToStr(row.original.networth, 0)}$
          </span>
        ),
        disableSortBy: true,
        className: 'w-32 md:w-40',
      },
    ],
    []
  );

  return <Table columns={columns} data={users} />;
};

export default UsersListTable;
