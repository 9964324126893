import axios, { CancelToken, CancelTokenSource } from 'axios';
import {
  Api,
  BundleDTO,
  BundleExchangeKeyDTO,
  ExchangeDTO,
  NetworkDTO,
  ProjectDTO,
} from 'api';
import BigNumber from 'bignumber.js';
import { makeAutoObservable, runInAction } from 'mobx';
import { NetworkModel } from 'models/portfolio/NetworkModel';
import { ProjectModel } from 'models/portfolio/ProjectModel';
import { WalletModel } from 'models/portfolio/WalletModel';
import { StaticDataStore } from 'stores/StaticDataStore';
import { BundleModel } from '../models/BundleModel';
import { BundlePortfolioModel } from '../models/BundlePortfolioModel';
import { ExchangeWalletModel } from 'models/portfolio/ExchangeWalletModel';
import { ExchangeStore } from 'stores/exchange-key/ExchangeStore';
import { showBetaFunctionality } from 'utils/beta';

export class BundlePortfolioStore {
  constructor(
    private api: Api<unknown>,
    private staticData: StaticDataStore,
    private exchangeStore: ExchangeStore
  ) {
    makeAutoObservable(this);
  }

  private cancelTokenSource?: CancelTokenSource;
  private currentBundleIdSelector?: string;

  private bundleMap = new Map<string, BundleModel>();
  private bundlePortfolioMap = new Map<string, BundlePortfolioModel>();
  private loadingMap = new Map<string, boolean>();

  get loading() {
    return this.loadingMap.size > 0;
  }

  get bundle() {
    if (!this.currentBundleIdSelector) {
      return undefined;
    }

    return this.bundleMap.get(this.currentBundleIdSelector);
  }

  get portfolio() {
    if (!this.currentBundleIdSelector) {
      return undefined;
    }

    return this.bundlePortfolioMap.get(this.currentBundleIdSelector)?.networks;
  }

  get totalUsd() {
    if (!this.currentBundleIdSelector) {
      return new BigNumber(0);
    }

    return (
      this.bundlePortfolioMap.get(this.currentBundleIdSelector)?.totalUsd ??
      new BigNumber(0)
    );
  }

  getBundleExchangeKey(bundleExchangeKeyId: string) {
    return this.bundle?.exchangeKeys.find((x) => x.id === bundleExchangeKeyId);
  }

  async fetchPortfolio(bundle: BundleDTO) {
    if (this.loading && this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }

    const cancelTokenSource = axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;

    runInAction(() => {
      this.loadingMap.set(bundle.id, true);
      this.currentBundleIdSelector = bundle.id;
      this.cancelTokenSource = cancelTokenSource;
    });

    try {
      const bundleModel = await this.fetchBundleModel(bundle, cancelToken);

      runInAction(() => {
        this.bundleMap.set(bundle.id, bundleModel);
      });

      const networkList = this.staticData.getNetworks();
      const exchangeList = this.exchangeStore.exchanges.filter((e) =>
        bundleModel.exchangeKeys.map((ek) => ek.exchange).includes(e.id)
      );

      const networks = await Promise.all(
        networkList.map((network) =>
          this.getNetworkModel(network, bundleModel, cancelToken)
        )
      );

      const exchanges = await Promise.all(
        exchangeList.map((exchange) =>
          this.getExchangeNetworkModel(exchange, bundleModel, cancelToken)
        )
      );

      runInAction(() => {
        this.cancelTokenSource = undefined;
        this.loadingMap.delete(bundle.id);
        this.bundlePortfolioMap.set(
          bundle.id,
          new BundlePortfolioModel(networks.concat(exchanges))
        );
      });
    } catch (err) {
      console.error(err);

      runInAction(() => {
        this.loadingMap.delete(bundle.id);
        this.bundlePortfolioMap.delete(bundle.id);
        this.cancelTokenSource = undefined;
      });
    }
  }

  private async fetchBundleModel(bundle: BundleDTO, cancelToken: CancelToken) {
    const bundleAccounts = await this.api.bundle.bundleAccountList(
      { id: bundle.id },
      { cancelToken: cancelToken }
    );

    const bundleExchangeKeys = showBetaFunctionality
      ? await this.api.bundle.bundleExchangeKeyList(
          { id: bundle.id },
          { cancelToken: cancelToken }
        )
      : { data: { exchangeKeys: [] as BundleExchangeKeyDTO[] } };

    return new BundleModel(
      bundle,
      bundleAccounts.data.accounts,
      bundleExchangeKeys.data.exchangeKeys
    );
  }

  private async getNetworkModel(
    network: NetworkDTO,
    bundle: BundleModel,
    cancelToken: CancelToken
  ) {
    const projectList = this.staticData.getProjects(network.id);

    const ProjectModel = Promise.all(
      projectList.map((proj) => this.getProjectModel(proj, bundle, cancelToken))
    );

    const portfolioData = await Promise.all([
      this.getWalletModel(network, bundle, cancelToken),
      ProjectModel,
    ]);

    const wallet = portfolioData[0];
    const projects = portfolioData[1];

    return new NetworkModel(network, wallet, projects);
  }

  private async getExchangeNetworkModel(
    exchange: ExchangeDTO,
    bundle: BundleModel,
    cancelToken: CancelToken
  ) {
    const wallet = await this.getExchangeWalletModel(
      exchange,
      bundle,
      cancelToken
    );

    return new NetworkModel(exchange, wallet, [], 'exchange');
  }

  private async getWalletModel(
    network: NetworkDTO,
    bundle: BundleModel,
    cancelToken: CancelToken
  ) {
    try {
      const result = await this.api.account.walletsAssets(
        {
          addresses: bundle.accounts.map((x) => x.address),
          networkId: network.id,
        },
        { cancelToken: cancelToken }
      );

      return result.data
        .map((x) => new WalletModel(x))
        .reduce((x, y) => x.combine(y), new WalletModel({ tokenBalances: [] }));
    } catch (err) {
      console.error(err);

      return new WalletModel({ tokenBalances: [] });
    }
  }

  private async getExchangeWalletModel(
    exchange: ExchangeDTO,
    bundle: BundleModel,
    cancelToken: CancelToken
  ) {
    try {
      const result = await Promise.all(
        bundle.exchangeKeys
          .filter((x) => x.exchange === exchange.id)
          .map((x) =>
            this.api.exchange.exchangeKeyBalances(
              { id: x.id },
              { cancelToken: cancelToken }
            )
          )
      );

      return result
        .map((x) => new ExchangeWalletModel(x.data))
        .reduce((x, y) => x.combine(y), new WalletModel({ tokenBalances: [] }));
    } catch (err) {
      console.error(err);

      return new WalletModel({ tokenBalances: [] });
    }
  }

  private async getProjectModel(
    project: ProjectDTO,
    bundle: BundleModel,
    cancelToken: CancelToken
  ) {
    try {
      const result = await this.api.account.accountsData(
        {
          accounts: bundle.accounts.map((x) => x.address),
          projectType: project.id,
        },
        { cancelToken: cancelToken }
      );

      return result.data
        .map((data) => new ProjectModel(project, data))
        .reduce(
          (x, y) => x.combine(y),
          new ProjectModel(project, {
            address: bundle.id,
            pools: [],
          })
        );
    } catch (err) {
      console.error(err);

      return new ProjectModel(project, {
        address: bundle.id,
        pools: [],
      });
    }
  }
}
