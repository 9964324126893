import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';
import { ThemeStore } from 'stores/ThemeStore';

const themeStoreContext = createContext<ThemeStore>({} as ThemeStore);

export const ProvideTheme = observer(({ children }: any) => {
  const store = useLocalObservable(() => new ThemeStore());

  useEffect(() => {
    store.init();
  }, [store]);

  if (!store.initialized) {
    return null;
  }

  return (
    <themeStoreContext.Provider value={store}>
      {children}
    </themeStoreContext.Provider>
  );
});

export const useTheme = () => {
  return useContext(themeStoreContext);
};
