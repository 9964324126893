import { useCallback, useMemo } from 'react';
import { BundleDTO } from 'api';
import * as yup from 'yup';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/core/input-field';
import Button from 'components/core/button';

interface IFormInput {
  bundleName: string;
}

const BundleFormSchema = yup.object({
  bundleName: yup
    .string()
    .max(40, 'Cannot be more than 40 characters')
    .nullable()
    .required('Field is required'),
});

const EditBundle = ({
  initValue,
  isCreate,
  onConfirm,
  onCancel,
}: {
  initValue?: BundleDTO;
  isCreate?: boolean;
  onConfirm: (data: BundleDTO) => void;
  onCancel: () => void;
}) => {
  const { handleSubmit, control } = useForm<IFormInput>({
    resolver: yupResolver(BundleFormSchema),
    mode: 'onChange',
    defaultValues: { bundleName: initValue?.name },
  });

  const applyForm = useCallback<SubmitHandler<IFormInput>>(
    (data) => {
      onConfirm({ id: initValue?.id ?? '', name: data.bundleName });
    },
    [onConfirm, initValue?.id]
  );

  const title = useMemo(
    () => (isCreate === true ? 'Create new bundle' : 'Edit bundle'),
    [isCreate]
  );

  const description = useMemo(
    () =>
      isCreate === true
        ? 'Enter a name for the new bundle'
        : 'Enter a name for the bundle',
    [isCreate]
  );

  return (
    <form className='flex flex-col' onSubmit={handleSubmit(applyForm)}>
      <div className='text-base'>{title}</div>
      <div className='text-secondary-1 text-sm my-2'>{description}</div>
      <Controller
        control={control}
        name='bundleName'
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <InputField
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            error={error?.message}
          />
        )}
      ></Controller>
      <div className='flex justify-between mt-6 flex-row-reverse'>
        <Button variant='primary' className='w-28' type='submit'>
          <span>Save</span>
        </Button>

        <Button
          variant='secondary'
          className='w-28'
          type='button'
          onClick={onCancel}
        >
          <span>Cancel</span>
        </Button>
      </div>
    </form>
  );
};

export default EditBundle;
