import './index.css';
import SnipaRoutes from 'routes';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'providers/AuthProvider';
import { Navigate, useLocation } from 'react-router-dom';
import laptopPng from 'assets/images/landing/laptop.png';
import laptopScreenDarkPng from 'assets/images/landing/screen-dark.png';
import laptopScreenLightPng from 'assets/images/landing/screen-light.png';
import dollarCoinPng from 'assets/images/landing/dollar-coin.png';
import everCoinPng from 'assets/images/landing/everscale-coin.png';
import { ReactComponent as CursorSvg } from 'assets/images/landing/cursor.svg';
import { ReactComponent as ArrowSvg } from 'assets/images/landing/arrow.svg';
import { useCallback } from 'react';
import { useTheme } from 'providers/ThemeProvider';
import { Theme } from 'stores/ThemeStore';
import Container from 'components/core/container';
import Button from 'components/core/button';

const LandingPage = observer(() => {
  const theme = useTheme();
  const auth = useAuthStore();
  const location = useLocation() as any;
  const from =
    (location.state?.from?.pathname as string) ??
    `/${SnipaRoutes.profile.path}`;

  const onLoginClick = useCallback(() => {
    auth.login();
  }, [auth]);

  if (auth.loggedIn) {
    return <Navigate to={from} replace />;
  }

  return (
    <Container className='relative my-5 md:my-0 flex flex-col items-center'>
      <span className='relative mt-8 mb-5 text-center font-bold text-lg md:text-3xl order-0 max-w-[300px] md:max-w-[450px]'>
        Access all your Everscale assets from a single interface
        {theme.current === Theme.Dark && (
          <ArrowSvg className='absolute pointer-events-none w-[120px] -top-[10px] -right-[15px] md:-top-[10px] md:-right-[35px]' />
        )}
      </span>

      <Button
        variant='primary'
        className='order-1 flex items-center gap-2'
        onClick={onLoginClick}
        style={{
          boxShadow:
            theme.current === Theme.Dark
              ? '0px 20px 30px rgba(0, 227, 180, 0.2)'
              : undefined,
        }}
      >
        <CursorSvg />
        <span className='text-sm font-bold'>Explore now with EVER Wallet</span>
      </Button>

      <div className='relative mt-12 order-first md:order-2'>
        {theme.current === Theme.Dark && (
          <div className='landing_page_desktop_particles absolute inset-0 md:-inset-12 w-full h-auto -z-10' />
        )}
        {theme.current === Theme.Dark && (
          <div className='landing_page_desktop_background absolute inset-0 -z-10' />
        )}
        <img
          className='w-full max-w-[340px] md:max-w-[600px]'
          src={laptopPng}
          alt=''
        />
        <img
          className='absolute m-auto top-[7%] left-0 right-0 w-3/4 h-auto'
          src={
            theme.current === Theme.Dark
              ? laptopScreenDarkPng
              : laptopScreenLightPng
          }
          alt=''
        />
        <img
          className='landing_coin top-[7%] left-[4%]'
          src={dollarCoinPng}
          alt=''
        />
        <img
          className='landing_coin bottom-[12%] right-[4%]'
          src={everCoinPng}
          alt=''
        />
      </div>

      <div className='w-full flex flex-col md:flex-row order-3 mt-6 mb-6 md:mt-14 text-center text-info-1'>
        <span className='landing_description_text'>
          Connect
          <a
            href='https://l1.broxus.com/everscale/wallet'
            target='_blank'
            rel='noreferrer'
            className='mx-1 text-highlight font-semibold underline'
          >
            EVER Wallet
          </a>
          in order to get the full experience. You might need to install it
          first in order to get access.
        </span>
        <span className='landing_description_text'>
          Explore the data from DEXes, bridges, governance platforms, dePools,
          and your wallet in one place.
        </span>
        <span className='landing_description_text'>
          The analytical tool allows you to create customized bundles and follow
          your favorite accounts in Everscale.
        </span>
      </div>
    </Container>
  );
});
export default LandingPage;
