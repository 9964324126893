import { useMemo } from 'react';
import { useMdMediaQuery } from 'utils/responsiveness';
import Table from 'components/core/table';
import Tokens from 'components/core/tokens';
import { bigNumberToStr } from 'utils/strings';
import { Column } from 'react-table';
import { SupplyTokenModel } from 'models/portfolio/SupplyTokenModel';
import LiquidityCards from './LiquidityCards';

const LiquidityTable = ({ tokens }: { tokens: SupplyTokenModel[] }) => {
  const columns = useMemo<Column<SupplyTokenModel>[]>(
    () => [
      {
        Header: 'Assets',
        accessor: 'symbol',
        Cell: ({ row }) => <Tokens tokens={[row.original]} />,
        disableSortBy: true,
      },
      {
        Header: 'Balance',
        accessor: 'amount',
        Cell: ({ row }) => (
          <div className='flex gap-x-1'>
            <span>
              {bigNumberToStr(row.original.amount, 3, {
                useShortNotation: true,
              })}
            </span>
            <span>{row.original.symbol}</span>
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: 'USD Value',
        accessor: 'usdValue',
        Cell: ({ row }) => (
          <div className='font-bold'>
            ${bigNumberToStr(row.original.usdValue, 0)}
          </div>
        ),
        sortType: (rowA, rowB) => {
          return rowA.original.usdValue.comparedTo(rowB.original.usdValue);
        },
        className: 'w-48',
      },
    ],
    []
  );

  const defaultSort = useMemo(() => ({ id: 'usdValue', desc: true }), []);
  const isDesktop = useMdMediaQuery();

  return isDesktop ? (
    <Table
      columns={columns}
      data={tokens}
      title='Liquidity'
      defaultSortBy={defaultSort}
    />
  ) : (
    <LiquidityCards data={tokens} />
  );
};

export default LiquidityTable;
