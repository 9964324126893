import { useMemo, useState } from 'react';
import { useMdMediaQuery } from 'utils/responsiveness';
import Table from 'components/core/table';
import { bigNumberToStr } from 'utils/strings';
import Tokens from 'components/core/tokens';
import { Column } from 'react-table';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { TokenBalanceModel } from 'models/portfolio/TokenBalanceModel';
import WalletCards from './WalletCards';

const tokenUsdThreshold = 100;
const tokenCountThreshold = 5;

const WalletTable = ({ assets }: { assets: TokenBalanceModel[] }) => {
  const [showAll, setShowAll] = useState(false);

  const enableHideFeature = useMemo(() => {
    if (assets.length <= tokenCountThreshold) {
      return false;
    }

    return true;
  }, [assets]);

  const tokensToDisplay = useMemo(() => {
    if (enableHideFeature === false || showAll) {
      return assets;
    }

    return assets.filter((x) => x.total.comparedTo(tokenUsdThreshold) > 0);
  }, [assets, enableHideFeature, showAll]);

  const columns = useMemo<Column<TokenBalanceModel>[]>(
    () => [
      {
        Header: 'Assets',
        accessor: 'symbol',
        Cell: ({ row }) => <Tokens tokens={[row.original]} />,
        disableSortBy: true,
      },
      {
        Header: 'Price',
        accessor: 'usdPrice',
        Cell: ({ row }) => (
          <span>${bigNumberToStr(row.original.usdPrice, 4)}</span>
        ),
        sortType: (rowA, rowB) => {
          return rowA.original.usdPrice.comparedTo(rowB.original.usdPrice);
        },
      },
      {
        Header: 'Balance',
        accessor: 'amount',
        Cell: ({ row }) => (
          <span>
            {bigNumberToStr(row.original.amount, 3, { useShortNotation: true })}
          </span>
        ),
        sortType: (rowA, rowB) => {
          return rowA.original.amount.comparedTo(rowB.original.amount);
        },
      },
      {
        Header: 'USD Value',
        accessor: 'total',
        Cell: ({ row }) => (
          <span className='font-bold'>
            ${bigNumberToStr(row.original.total, 0)}
          </span>
        ),
        sortType: (rowA, rowB) => {
          return rowA.original.total.comparedTo(rowB.original.total);
        },
        className: 'w-48',
      },
    ],
    []
  );

  const defaultSort = useMemo(() => ({ id: 'total', desc: true }), []);
  const isDesktop = useMdMediaQuery();

  return (
    <div className='flex flex-col gap-5'>
      {isDesktop ? (
        <Table
          columns={columns}
          data={tokensToDisplay}
          defaultSortBy={defaultSort}
        />
      ) : (
        <WalletCards assets={tokensToDisplay} />
      )}

      {enableHideFeature && (
        <div className='flex flex-col md:flex-row items-center justify-center text-center gap-2'>
          <span>
            Tokens with less than ${tokenUsdThreshold} in value have been
            hidden.
          </span>

          <span
            className='flex items-center text-highlight whitespace-nowrap cursor-pointer underline font-semibold'
            onClick={() => {
              setShowAll((currentValue) => !currentValue);
            }}
          >
            {showAll ? 'Hide' : 'Show'} all
            {showAll ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </span>
        </div>
      )}
    </div>
  );
};

export default WalletTable;
