import SnipaRoutes from 'routes';
import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStaticData } from 'providers/StaticDataStoreProvider';
import { BsChevronLeft } from 'react-icons/bs';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  ProvideProjectStore,
  useProjectStore,
} from '../providers/ProjectStoreProvider';
import Container from 'components/core/container';
import TabsSelect from 'components/core/tabs-select';
import ProjectAnalytics from './ProjectAnalytics';
import ProjectUsers from './ProjectUsers';

const ProjectDetailsHeader = observer(() => {
  const staticData = useStaticData();
  const projectStore = useProjectStore();

  const projectTabs = useMemo(
    () => [
      { id: 'users', text: 'Users', navLink: '' },
      {
        id: 'analytics',
        text: 'Analytics',
        navLink: SnipaRoutes.projects.details.analytics.path,
      },
    ],
    []
  );

  return (
    <div className='bg-navbar-mainBg'>
      <Container className='pt-3 sm:pt-4'>
        <NavLink
          className='flex items-center gap-2 mb-4'
          to={`/${SnipaRoutes.projects.path}`}
        >
          <BsChevronLeft className='text-lg' />
          <span>Back to projects</span>
        </NavLink>
        <div className='flex items-center mb-4 gap-3'>
          {projectStore.project && (
            <img
              src={staticData.getProjectLogo(projectStore.project.id)}
              className='w-9 h-9'
              alt=''
            />
          )}

          <span className='text-xl font-bold'>
            {projectStore.project?.name}
          </span>
        </div>
        <div className='flex items-center mb-4'>
          <a
            className='underline text-highlight'
            href={projectStore.project?.link}
            target='_blank'
            rel='noreferrer'
          >
            <span>{projectStore.project?.link}</span>
          </a>
        </div>
        <div className='flex items-center gap-2 mb-4'>
          <span className='text-secondary-1'>Total users</span>
          <span className='font-bold'>
            {projectStore.project?.totalUsers ?? 0}
          </span>
        </div>

        <TabsSelect data={projectTabs} />
      </Container>
    </div>
  );
});

const ProjectDetails = () => {
  const { projectId } = useParams();

  if (!projectId) {
    return null;
  }

  return (
    <ProvideProjectStore projectId={projectId}>
      <ProjectDetailsHeader />

      <Container className='my-10'>
        <Routes>
          <Route index element={<ProjectUsers />} />
          <Route
            path={`${SnipaRoutes.projects.details.analytics.path}`}
            element={<ProjectAnalytics />}
          />
          <Route path='*' element={<Navigate to='' />} />
        </Routes>
      </Container>
    </ProvideProjectStore>
  );
};

export default ProjectDetails;
