import { useMemo } from 'react';
import { useMdMediaQuery } from 'utils/responsiveness';
import { bigNumberToStr } from 'utils/strings';
import Tokens from 'components/core/tokens';
import TokenValues from 'components/core/token-values';
import { Column } from 'react-table';
import FarmingCards from './FarmingCards';
import Table from 'components/core/table';
import { FarmingRewardModel } from 'models/portfolio/FarmingRewardModel';
import { PoolModel } from 'models/portfolio/PoolModel';
import { SupplyTokenModel } from 'models/portfolio/SupplyTokenModel';

const FarmingTable = ({
  title,
  poolInfo,
}: {
  title: string;
  poolInfo: PoolModel[];
}) => {
  const columns = useMemo<Column<PoolModel>[]>(
    () => [
      {
        Header: 'Pool',
        accessor: 'type',
        Cell: ({ row }) => <Tokens tokens={row.original.supplyTokenList} />,
        disableSortBy: true,
      },
      {
        Header: 'Balance',
        accessor: 'supplyTokenList',
        Cell: ({ row }) => (
          <TokenValues
            tokens={row.original.supplyTokenList.map(
              (token: SupplyTokenModel) => ({
                amount: token.amount,
                symbol: token.symbol,
                usdValue: token.usdValue,
              })
            )}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Vested',
        accessor: 'rewardTokenList',
        Cell: ({ row }) => (
          <TokenValues
            tokens={row.original.rewardTokenList?.map(
              (token: FarmingRewardModel) => ({
                amount: token.vestedAmount,
                symbol: token.symbol,
                usdValue: token.vestedAmountUsdValue,
              })
            )}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Entitled',
        accessor: 'stakingRewardList',
        Cell: ({ row }) => (
          <TokenValues
            tokens={row.original.rewardTokenList?.map(
              (token: FarmingRewardModel) => ({
                amount: token.entitledAmount,
                symbol: token.symbol,
                usdValue: token.entitledAmountUsdValue,
              })
            )}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'USD Value',
        accessor: 'totalUsdValue',
        Cell: ({ row }) => (
          <span className='font-bold'>
            ${bigNumberToStr(row.original.totalUsdValue, 0)}
          </span>
        ),
        sortType: (rowA, rowB) => {
          return rowA.original.totalUsdValue.comparedTo(
            rowB.original.totalUsdValue
          );
        },
        className: 'w-48',
      },
    ],
    []
  );

  const defaultSort = useMemo(() => ({ id: 'totalUsdValue', desc: true }), []);

  const isDesktop = useMdMediaQuery();

  return isDesktop ? (
    <Table
      columns={columns}
      data={poolInfo}
      title={title}
      defaultSortBy={defaultSort}
    />
  ) : (
    <FarmingCards poolInfo={poolInfo} />
  );
};

export default FarmingTable;
