import Tokens from 'components/core/tokens';
import TokenValues from 'components/core/token-values';
import { PoolModel } from 'models/portfolio/PoolModel';
import { bigNumberToStr } from 'utils/strings';
import ProjectCardTitle from '../project/ProjectCardTitle';
import Card from 'components/core/card';

const FarmingCards = ({ poolInfo }: { poolInfo: PoolModel[] }) => {
  return (
    <div className='flex flex-col gap-3'>
      {poolInfo.map((item, index) => (
        <Card key={index} className=''>
          <ProjectCardTitle title='Farming' />

          <div className='grid grid-cols-2 gap-2'>
            <Tokens tokens={item.supplyTokenList} />

            <div>
              <span className='text-secondary-1'>USD Value</span>
              <div className='text-sm font-bold'>
                <span>${bigNumberToStr(item.totalUsdValue, 0)}</span>
              </div>
            </div>

            <div>
              <span className='text-secondary-1'>Vested</span>
              <TokenValues
                tokens={item.rewardTokenList?.map((x) => ({
                  symbol: x.symbol,
                  amount: x.vestedAmount,
                  usdValue: x.vestedAmountUsdValue,
                }))}
              />
            </div>

            <div>
              <span className='text-secondary-1'>Entitled</span>
              <TokenValues
                tokens={item.rewardTokenList?.map((x) => ({
                  symbol: x.symbol,
                  amount: x.entitledAmount,
                  usdValue: x.entitledAmountUsdValue,
                }))}
              />
            </div>

            <div className='col-span-2'>
              <span className='text-secondary-1'>Balance</span>
              <TokenValues
                tokens={item.supplyTokenList?.map((x) => ({
                  symbol: x.symbol,
                  amount: x.amount,
                  usdValue: x.usdValue,
                }))}
              />
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};
export default FarmingCards;
